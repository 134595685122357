import React, { useContext } from 'react';
import T from 'prop-types';
import styled, { ThemeContext } from 'styled-components';
import { themeVal } from '@devseed-ui/theme-provider';
import { Heading } from '@devseed-ui/typography';

import Constrainer from '../../styles/constrainer';

import SandboxLayout from './_layout';

// How to add a new sandbox page:
// Follow the instructions on ./_layout.js

const ColorsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 1rem;
`;

const Color = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
`;

const ColorSquare = styled.div`
  width: 4rem;
  height: 4rem;
  margin-bottom: 1rem;
  border: 1px dashed #333;
  border-radius: ${themeVal('shape.rounded')};
  background: ${({ color }) => color};
`;

// To be able to access the theme as a prop we need the component to be a child
// of the theme provider. Since SandboxColors is importing the Layout and
// consequently the theme provider, we can't access the theme through it.
const Comp = () => {
  const theme = useContext(ThemeContext);
  return (
    <React.Fragment>
      <Heading as='h2'>Colors</Heading>
      <ColorsGrid>
        {Object.keys(theme.color).map((key) => (
          <Color key={key}>
            <ColorSquare color={theme.color[key]} />
            <strong>color.{key}</strong>
            <p>{theme.color[key]}</p>
          </Color>
        ))}
      </ColorsGrid>
    </React.Fragment>
  );
};

const SandboxColors = ({ location }) => {
  return (
    <SandboxLayout location={location} title='Sandbox colors'>
      <Constrainer>
        <Comp />
      </Constrainer>
    </SandboxLayout>
  );
};

export default SandboxColors;

SandboxColors.propTypes = {
  location: T.object
};
